import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:id?",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home/home.vue"),
    meta: {
      // 页面标题title
      title: " Hi星球",
    },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
